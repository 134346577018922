export const filetypes = {
  excel: [
    ".xlsx",
    "application/excel",
    "application/vnd.ms-excel",
    "application/vnd.msexcel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ],
  image: ["image/jpeg", "image/png"],
  pdf: ["application/pdf"],
};

export const convertFilelistToArray = (filelist: FileList): File[] => {
  const files: File[] = [];
  for (const file of filelist) {
    files.push(file);
  }
  return files;
};

export const formatBytes = (bytes: number): string => {
  const sizes = ["B", "KB", "MB", "GB", "TB"];

  if (bytes === 0) return "0 B";

  const i = Math.floor(Math.log(bytes) / Math.log(1000));
  const value = bytes / Math.pow(1000, i);

  return `${value.toFixed(2)} ${sizes[i]}`;
};

export const getFileExtension = (filename: string): string => {
  return !filename.includes(".")
    ? ""
    : filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
};

export const removeFileExtension = (filename: string): string => {
  const lastDotIndex = filename.lastIndexOf(".");
  if (lastDotIndex === -1) {
    return filename;
  } else {
    return filename.slice(0, lastDotIndex);
  }
};

export const fileSizes = {
  kilobyte: 1024,
  megabyte: 1024 * 1024,
  gigabyte: 1024 * 1024 * 1024,
};

export function showDownloadBlockedWarning() {
  useNuxtApp().$toast("Permita os pop-ups para ver o arquivo!", {
    autoClose: 3000,
    type: "warning",
  });
}

export function filterFilesByExtension(
  files: File | File[],
  accept?: string[]
): File[] | undefined {
  if (!accept) return Array.isArray(files) ? files : [files];
  else {
    if (Array.isArray(files)) {
      const validFiles = files.filter((file) => accept?.includes(file.type));
      return validFiles.length ? validFiles : undefined;
    } else {
      return accept.includes(files.type) ? [files] : undefined;
    }
  }
}
