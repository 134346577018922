<script setup lang="ts">
import AtomsIcon from "../icon/index.vue";
import { useDropZone, useFileDialog } from "@vueuse/core";
import { ref, watch } from "vue";
import { convertFilelistToArray } from "../../../utils/files";
import FileSelecteds from "./file-selecteds.vue";
import { makeErrorMessage } from "../../../utils/error";

const props = withDefaults(
  defineProps<{
    title?: string;
    description?: string;
    modelValue?: File | File[];
    accept?: string[];
    multiple?: boolean;
    canRemove?: boolean;
    errorMessage?: string;
    maxSize?: number;
  }>(),
  { canRemove: true }
);

const emit = defineEmits<{
  (e: "update:modelValue", value?: File | File[] | null): void;
}>();

/**
 * FILE INPUT/DIALOG TRATAMENT
 */
const { open, onChange, reset } = useFileDialog({
  accept: props.accept?.join(","),
  multiple: props.multiple,
});
onChange((_files) => {
  if (!_files || !_files.length) return;

  const files = convertFilelistToArray(_files);
  handleChangeFile(files);
});

/**
 * DROPZONE TRATAMENT
 */
const dropZoneRef = ref<HTMLDivElement>();
const { isOverDropZone } = useDropZone(dropZoneRef, {
  onDrop: (files) => {
    if (!files) return;
    handleChangeFile(files);
  },
  // specify the types of data to be received.
  dataTypes: props.accept,
});

/**
 * Valor interno para um melhor controle
 */
const innerValue = ref<File | File[]>();
watch(
  () => props.modelValue,
  (_value) => (_value !== innerValue.value ? (innerValue.value = _value) : null)
);
watch(
  () => innerValue.value,
  (_value) => emit("update:modelValue", _value)
);

/**
 * Valida o tamanho de um arquivo
 */
const validateFileSize = (_file: File) => {
  if (props.maxSize && _file.size > props.maxSize) {
    return false;
  }
  return true;
};

/**
 * Função genérica para quando mudar a seleção
 */
const handleChangeFile = (files: File[] | File) => {
  const validFiles = filterFilesByExtension(files, props.accept);
  if (!validFiles || !validFiles.length) {
    // #TODO melhor feedback de arquivos inválidos
    useNuxtApp().$toast("Arquivo(s) inválido(s)!", {
      type: "error",

      autoClose: 1500,
    });
    return;
  }

  // Valida o tamanho do(s) arquivo(s)
  if (props.maxSize) {
    if (props.multiple) {
      let existInvalid = false;
      for (const fileIndex in validFiles) {
        if (!validateFileSize(validFiles[fileIndex])) {
          existInvalid = true;
          validFiles.splice(Number(fileIndex), 1);
        }
      }
      if (existInvalid) {
        useNuxtApp().$toast("Arquivo(s) maior(es) que o permitido!", {
          type: "error",
        });
      }
      if (!validFiles.length) {
        reset();
        return;
      }
    } else {
      if (Array.isArray(files) && !validateFileSize(files[0])) {
        useNuxtApp().$toast("Arquivo maior que o permitido!", {
          type: "error",
        });
        reset();
        return;
      }
    }
  }

  if (!props.multiple) {
    innerValue.value = validFiles[0];
    return;
  }

  innerValue.value = validFiles;
};

/**
 * Função de remover o(s) arquivo(s) selecionado(s)
 */
const removeFile = (filename: string) => {
  if (Array.isArray(innerValue.value)) {
    if (innerValue.value.length > 1) {
      const fileIndex = innerValue.value.findIndex(
        (file) => file.name === filename
      );
      if (fileIndex > -1) {
        innerValue.value?.splice(fileIndex, 1);
      }
    } else {
      innerValue.value = undefined;
      reset();
    }
  } else {
    reset();
    innerValue.value = undefined;
  }
};
</script>

<template>
  <div class="file w-[383px] max-w-full" ref="dropZoneRef">
    <span class="file__title" v-if="title"> {{ title }}</span>
    <div class="file__area" :class="isOverDropZone && 'drop-over'">
      <button class="file__area__upload" @click="() => open()">
        <AtomsIcon name="upload" class="file__area__icon" :size="42" />
        <span class="file__area__title">
          Enviar um arquivo ou arraste e solte
        </span>
        <span v-if="description" class="file__area__description">
          {{ description }}
        </span>
      </button>

      <FileSelecteds
        :files="innerValue"
        :can-remove="canRemove"
        @remove="removeFile"
      />
    </div>
    <div class="file__feedback" v-if="errorMessage">
      <span class="file__feedback__error" v-if="errorMessage">
        {{ makeErrorMessage(errorMessage) }}
      </span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.file {
  @apply flex flex-col gap-[6px];

  &__title {
    @apply w-full text-left text-[16px] leading-5 text-gray-dark;
  }

  &__feedback {
    // @apply mt-1;
    &__error {
      @apply text-[14px] leading-5 text-danger;
    }
  }

  &__area {
    @apply w-full min-h-[140px]  rounded-[6px] border-2 border-gray-300 border-dashed
    flex flex-col gap-1 justify-center items-center
    transition-[background];
    &__upload {
      @apply w-full cursor-pointer flex flex-col gap-1 justify-center items-center
      pt-[22px] pb-[26px];
    }
    &:hover,
    &.drop-over {
      @apply bg-[rgb(240,240,240)];
    }
    &.drop-over {
      @apply cursor-copy;
    }

    &__icon {
      @apply text-primary;
    }

    &__title {
      @apply text-primary text-[14px] leading-5 font-medium;
    }

    &__description {
      @apply text-gray-dark text-[12px] leading-4;
    }
  }
}
</style>
