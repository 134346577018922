<script setup lang="ts">
import { computed } from "vue";
import {
  formatBytes,
  getFileExtension,
  removeFileExtension,
} from "../../../utils/files";
import AtomsIcon from "../icon/index.vue";

const props = withDefaults(
  defineProps<{
    files?: File | File[];
    canRemove?: boolean;
  }>(),
  {
    canRemove: true,
  }
);

const emit = defineEmits<{
  (e: "remove", filename: string): void;
}>();

const makeFileInfos = (file: File) => ({
  fullname: file.name,
  name: removeFileExtension(file.name),
  size: formatBytes(file.size),
  extension: getFileExtension(file.name),
});

const selectedFiles = computed(() => {
  if (!props.files) return [];
  if (Array.isArray(props.files))
    return props.files.map((f) => makeFileInfos(f));
  else return [makeFileInfos(props.files)];
});
</script>

<template>
  <div v-if="files" class="file-selecteds">
    <div
      class="file-selecteds__item"
      v-for="(file, fileIndex) in selectedFiles"
      :key="fileIndex + file.size"
    >
      <span class="file-selecteds__item__name">
        {{ file.name }}
      </span>
      <span class="file-selecteds__item__size">
        {{ file.size }}
      </span>
      <span class="file-selecteds__item__extension">
        {{ file.extension }}
      </span>
      <div class="file-selecteds__item__remove" v-if="canRemove">
        <button @click="emit('remove', file.fullname)">
          <AtomsIcon name="x" :size="16" />
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.file-selecteds {
  @apply w-full flex flex-wrap gap-4 mt-1 px-4 mb-4;

  &__item {
    @apply w-[140px] p-3 rounded-[10px] border border-gray-300 text-gray-dark
    flex flex-col text-left animate-fadeIn;

    &__name {
      @apply text-[14px] leading-4 font-medium line-clamp-2;
    }
    &__size,
    &__extension {
      @apply text-[12px] leading-4;
    }
    &__extension {
      @apply uppercase;
    }
    &__remove {
      @apply w-full flex justify-end mt-1;
      button {
        @apply cursor-pointer rounded-lg p-1 border border-gray-300 hover:border-gray-400 flex;
      }
    }
  }
}
</style>
