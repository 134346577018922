<script setup lang="ts">
import { onClickOutside, onKeyDown } from "@vueuse/core";
import { ref } from "vue";
import AtomsIcon from "../icon/index.vue";

const props = withDefaults(
  defineProps<{
    open?: boolean;
    title?: string;
    subtitle?: string;
    closable?: boolean;
    contentCenter?: boolean;
    scrollContent?: boolean;
  }>(),
  {
    open: false,
    closable: true,
  }
);
const emit = defineEmits<{
  (e: "update:open", value: boolean): void;
}>();

const modal = ref<HTMLDivElement>();
const modalContent = ref<HTMLDivElement>();
const modalContentHead = ref<HTMLDivElement>();
const modalContentBottom = ref<HTMLDivElement>();

const close = () => {
  if (!props.closable) return;
  emit("update:open", false);
};

onClickOutside(modalContent, close);
onKeyDown("Escape", close);

defineExpose({
  close,
});
</script>

<template>
  <Transition name="modal-transition">
    <div class="modal" ref="modal" v-if="open">
      <aside class="modal__content" ref="modalContent">
        <button
          class="modal__content__close"
          type="button"
          @click="close"
          v-if="closable"
        >
          <AtomsIcon name="x" :size="24" class="text-primary" />
        </button>
        <div
          class="modal__content__head"
          v-if="title || subtitle"
          ref="modalContentHead"
        >
          <span class="modal__content__head__title" v-if="title">{{
            title
          }}</span>
          <span class="modal__content__head__subtitle" v-if="subtitle">{{
            subtitle
          }}</span>
        </div>
        <div
          class="relative w-full"
          :class="[
            contentCenter && 'flex flex-col items-center justify-center',
            scrollContent && 'h-full max-h-full overflow-y-auto',
          ]"
        >
          <slot></slot>
        </div>
        <div class="modal__content__bottom" ref="modalContentBottom">
          <slot name="actions"></slot>
        </div>
      </aside>
    </div>
  </Transition>
</template>

<style lang="scss">
.modal-transition {
  &-leave-active {
    transition: opacity 0.3s ease 0s;
    @screen lg {
      .modal__content {
        transition: translate 0.5s ease;
        translate: 430px;
      }
    }
  }
  &-leave-to {
    opacity: 0;
  }
}

@keyframes modalContentSlide {
  0% {
    opacity: 0;
    translate: 500px;
  }
  100% {
    opacity: 1;
    translate: 0;
  }
}

.modal {
  @apply w-full h-[100vh] fixed left-0 top-0 z-50 bg-[rgba(0,0,0,0.6)]
  flex justify-center lg:justify-end items-center lg:items-stretch animate-fadeIn;

  &__content {
    @apply w-[95%] lg:w-[480px] min-h-[40%] lg:min-h-[auto] lg:h-full overflow-auto bg-white  rounded-l-[10px] rounded-r-[10px] lg:rounded-r-none
    p-[24px_24px_16px] lg:p-[48px] relative flex flex-col items-stretch;

    @screen lg {
      animation: modalContentSlide 0.3s ease;
    }

    &__close {
      @apply h-[32px] w-[32px] flex items-center justify-center bg-transparent
      absolute top-2 lg:top-4 right-2 lg:right-4;
    }

    &__head {
      @apply flex flex-col mb-8;
      &__title {
        @apply text-primary text-[24px] leading-5 font-bold pb-2;
      }

      &__subtitle {
        @apply text-primary text-[16px] leading-5 font-normal;
      }
    }

    &__bottom {
      @apply w-full mt-auto;
    }
  }
}
</style>
